.link_style {
  text-decoration: none;
}
::-webkit-scrollbar {
  background: #072f4f;
  width: 12px;
}
::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #9691ff, #1aa8ff);
  border-radius: 10px;
}
