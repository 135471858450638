.login {
  text-align: center;
}

.login-box {
  border: 5px solid #222f3e;
  background-color: #576574;
  margin: 5px 30%;
  border-radius: 10px;
}
.login-btn {
  border-radius: 10px;
  padding: 10px;
  border: 1px solid gray;
  background-color: white;
  margin-bottom: 10px;
}

.login-btn img {
  margin-right: 50px;
}
