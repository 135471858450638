.header {
  height: 100px;
  color: white;
  background: #0a304e;
}
.sidebar {
  width: 300px;
  height: 600px;
  background: #eec620;
}
