.main_header {
  background: linear-gradient(0deg, #2d343696, #636e72a4),
    url("../../../assets/header_bg.jpg");
  background-size: cover;
  background-attachment: fixed;
  height: 600px;
  position: relative;
}
.buttons {
  position: absolute;
  top: 70%;
  left: 50%;
}
.buttons #first {
  background-color: #0984e3;
  color: white;
}
.buttons #second {
  background-color: #fdcb6e;
  color: #2d3436;
}
.big_text {
  position: absolute;
  top: 50%;
  left: 50%;
}
@media (max-width: 1700px) {
  .big_text {
    top: 30%;
    left: 40%;
  }
  .buttons {
    left: 40%;
    top: 50%;
  }
}
@media (max-width: 780px) {
  .big_text,
  .buttons {
    left: 40%;
  }
}
/* @media (max-width: 768px) {
  .big_text {
    top: 40%;
    left: 20%;
  }
  .buttons {
    top: 50%;
    left: 20%;
  }
} */
@media (max-width: 600px) {
  .big_text,
  .buttons {
    left: 30%;
  }
}
@media (max-width: 550px) {
  .big_text,
  .buttons {
    left: 20%;
  }
}
@media (max-width: 500px) {
  .big_text {
    left: 10%;
  }
  .buttons {
    top: 50%;
    left: 10%;
  }
}
