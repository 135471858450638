.testimonial_card_parent {
  width: 500px;

  /* height: 350px !important; */
  height: 40vh;

  border-radius: 20px;
  margin: 0 40px 30px -100px;
  /* border: 4px dotted   #072f4f; */
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.07);

  padding: 20px;
  transition: all 0.2s ease;
  cursor: pointer;
}
.people {
  border-radius: 10px;
  width: 100px !important;
}

@media (max-width: 1000px) {
  .testimonial_card_parent {
    width: 250px;
    height: 300px;
    overflow: hidden;
    /* margin-left: -50px; */
  }
  .people {
    display: none;
  }
}
