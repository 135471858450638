.orders {
  width: 300px;
  height: 190px;
  background: tomato;
  color: white;
  padding: 20px;
  margin: 30px;
  margin-top: 8px;
  border-radius: 20px;
}
