@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
.link_style {
  text-decoration: none;
}
::-webkit-scrollbar {
  background: #072f4f;
  width: 12px;
}
::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #9691ff, #1aa8ff);
  border-radius: 10px;
}

.header {
  height: 100px;
  color: white;
  background: #0a304e;
}
.sidebar {
  width: 300px;
  height: 600px;
  background: #eec620;
}

.sidebar_link svg {
  margin-right: 10px;
}
.sidebar_link {
  list-style: none;
  font-size: 20px;
  padding: 20px 20px 0 20px;
  color: black;
}

.orders_table {
  padding: 20px;
  margin: 20px;
  border: 5px solid #2d3436;
  height: 500px;
  overflow: auto;
}

.payment_form {
  width: 300px;
  padding: 20px;
  margin-bottom: 10px;
  background: #dfe6e9;
  border-radius: 20px;
}

.form {
  width: 1000px;
  margin-left: 25px;
  padding: 20px;
}

.orders {
  width: 300px;
  height: 190px;
  background: tomato;
  color: white;
  padding: 20px;
  margin: 30px;
  margin-top: 8px;
  border-radius: 20px;
}

.img {
  filter: grayscale(1);
  transition: all 0.2s ease;
}
.img:hover {
  filter: none;
}

.blog__card {
  width: 300px;
  height: 370px;
  padding: 20px;
  margin: 20px;
  box-shadow: 3px 7px 22px 3px rgba(179, 175, 179, 0.7);
}

.contact_form {
  padding: 0 150px 150px 150px;
}

footer img {
  height: 500px;
}
footer {
  position: relative;
}
footer h3 {
  position: absolute;
  top: 50%;
  left: 40%;
}
footer p {
  position: absolute;
  top: 60%;
  left: 30%;
}
footer svg {
  font-size: 40px !important;
  margin: 0 10px;
  color: #dfe6e9;
  cursor: pointer;
}
footer #facebook {
  position: absolute;
  top: 357px;
}
footer #insta {
  position: absolute;
  top: 357px;
  left: 55%;
}
footer #twitter {
  position: absolute;
  top: 357px;
  left: 45%;
}
.copyright {
  margin-top: 150px;
  margin-left: 70px;
  color: white;
}
@media (max-width: 935px) {
  footer p {
    margin-left: -40px;
  }
  .copyright {
    margin-left: 0px;
  }
  footer #twitter {
    left: 40%;
  }
  footer #insta {
    left: 60%;
  }
}
@media (max-width: 750px) {
  footer p {
    margin-left: -10px;
  }
  .copyright {
    margin-left: 0px;
  }
  footer #twitter {
    left: 40%;
  }
  footer #insta {
    left: 60%;
  }
}
@media (max-width: 750px) {
  .smal_title {
    margin-right: 70px;
  }
  .copyright {
    margin-left: -20px;
  }
  footer h3 {
    margin-left: -50px;
  }
  footer #twitter {
    left: 40%;
    margin-top: 20px;
  }
  footer #insta {
    left: 60%;
    margin-top: 20px;
  }
  footer #facebook {
    margin-top: 20px;
  }
}

.main_header {
  background: linear-gradient(0deg, #2d343696, #636e72a4),
    url(/static/media/header_bg.35f4a801.jpg);
  background-size: cover;
  background-attachment: fixed;
  height: 600px;
  position: relative;
}
.buttons {
  position: absolute;
  top: 70%;
  left: 50%;
}
.buttons #first {
  background-color: #0984e3;
  color: white;
}
.buttons #second {
  background-color: #fdcb6e;
  color: #2d3436;
}
.big_text {
  position: absolute;
  top: 50%;
  left: 50%;
}
@media (max-width: 1700px) {
  .big_text {
    top: 30%;
    left: 40%;
  }
  .buttons {
    left: 40%;
    top: 50%;
  }
}
@media (max-width: 780px) {
  .big_text,
  .buttons {
    left: 40%;
  }
}
/* @media (max-width: 768px) {
  .big_text {
    top: 40%;
    left: 20%;
  }
  .buttons {
    top: 50%;
    left: 20%;
  }
} */
@media (max-width: 600px) {
  .big_text,
  .buttons {
    left: 30%;
  }
}
@media (max-width: 550px) {
  .big_text,
  .buttons {
    left: 20%;
  }
}
@media (max-width: 500px) {
  .big_text {
    left: 10%;
  }
  .buttons {
    top: 50%;
    left: 10%;
  }
}

.nav-link:hover {
  background-color: #0984e3 !important;
  border-radius: 5px;
}
.btn-primary {
  background-color: #0984e3 !important;
}

.card_parent {
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  border-radius: 20px;
  margin: 20px 40px;
  transition: all 0.2s ease;
  cursor: pointer;
}
.card_parent:hover {
  box-shadow: 0 1.9px 13.1px rgba(0, 0, 0, 0.155),
    0 15px 105px rgba(0, 0, 0, 0.31);
  transform: scale(1.1);
}
.card_parent img:hover {
  animation: spin 4s ease infinite;
}
.icon {
  height: 100px;
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.testimonial_card_parent {
  width: 500px;

  /* height: 350px !important; */
  height: 40vh;

  border-radius: 20px;
  margin: 0 40px 30px -100px;
  /* border: 4px dotted   #072f4f; */
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.07);

  padding: 20px;
  transition: all 0.2s ease;
  cursor: pointer;
}
.people {
  border-radius: 10px;
  width: 100px !important;
}

@media (max-width: 1000px) {
  .testimonial_card_parent {
    width: 250px;
    height: 300px;
    overflow: hidden;
    /* margin-left: -50px; */
  }
  .people {
    display: none;
  }
}


.login {
  text-align: center;
}

.login-box {
  border: 5px solid #222f3e;
  background-color: #576574;
  margin: 5px 30%;
  border-radius: 10px;
}
.login-btn {
  border-radius: 10px;
  padding: 10px;
  border: 1px solid gray;
  background-color: white;
  margin-bottom: 10px;
}

.login-btn img {
  margin-right: 50px;
}

.preloader_container {
  width: 100vw;
  height: 100vh;
  background: #21242d;
}
.preloader_container div img {
  height: 100vh;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

